import { AccountStore, ConfigsStore } from '../Store';
import { API_KEY } from '../configs';
import axios from 'axios';
import { IAccount } from '../types';
import dayjs from 'dayjs';
import { apiRequest, apiRequestPost } from '../rest-data-provider/utils/APIs';
import { TOKEN_KEY } from '../authProvider';
import { AuthActionResponse } from '@refinedev/core/dist/contexts/auth/types';
import { message } from 'antd';

export const axiosInstance = axios.create();
let failedRequestCount = 0;

const setHeaders = async () => {
  try {
    const account = await AccountStore.get('account');
    const token = localStorage.getItem('access_token');
    if (account && account.signedAccessToken) {
      axiosInstance.interceptors.request.use(async (config) => {
        config.headers['Authorization'] = `Bearer ${token}`;
        config.headers['api_key'] = API_KEY;
        return config;
      });
    } else {
      axiosInstance.interceptors.request.use(async (config) => {
        config.headers['api_key'] = API_KEY;
        return config;
      });
    }
  } catch (error) {
    error;
  }
};
axiosInstance.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem('access_token');
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    config.headers['api_key'] = API_KEY;
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);
axiosInstance.interceptors.response.use(
  (response) => {
    // Reset the counter on a successful response
    failedRequestCount = 0;
    return response;
  },
  (error) => {
    if (
      error.response &&
      (error.response.status === 401 || error.response.status === 403)
    ) {
      failedRequestCount += 1;

      if (failedRequestCount > 1) {
        // Clear the token or any other relevant data
        localStorage.removeItem(TOKEN_KEY);
        AccountStore.set('account', {});
        ConfigsStore.set('logged_in', false);

        // Redirect to login page
        window.location.href = '/login'; // Or use a method provided by your routing library

        // Reset the counter after redirect
        failedRequestCount = 0;
      }
    }
    return Promise.reject(error);
  }
);
export const LoginAction = async (loginInput: {
  email: string;
  password: string;
  // account_type: string;
}): Promise<AuthActionResponse> => {
  try {
    const { data: accountData } = await apiRequest<IAccount>({
      url: '/admin/login',
      data: loginInput,
      method: 'POST',
    });
    if (!accountData) throw new Error('Account Not Found!');
    await AccountStore.set('account', accountData);
    await setHeaders();
    await ConfigsStore.set('logged_in', true);
    await ConfigsStore.set('resetPasswordEmail', '');
    if (await ConfigsStore.get('rememberMe')) {
      const cookies = {
        value: await ConfigsStore.get('logged_in'),
        expiry: dayjs(Date()).add(15, 'd').toDate(),
      };
      await ConfigsStore.set('cookieExpiration', cookies);
    }
    axios.defaults.headers.common['Authorization'] =
      `Bearer ${accountData.signedAccessToken}`;
    localStorage.setItem(TOKEN_KEY, accountData.user.username);
    localStorage.setItem('access_token', accountData.signedAccessToken);

    return {
      success: true,
      message: 'Login Successful',
      redirectTo: '/',
    };
  } catch (error) {
    return {
      success: false,
      error: {
        name: 'Login Error',
        //@ts-ignore
        message: `${error.response.data.message == 'Invalid_Email_OR_Password' ? 'Invalid Email or Password' : ''}`,
      },
    };
  }
};
export const ForgotPasswordAction = async (inputs: { email: string }) => {
  try {
    await apiRequestPost<boolean>({
      url: '/admin/forget-password',
      data: inputs,
    });
    await ConfigsStore.set('resetPasswordEmail', inputs.email);
    return {
      success: true,
      redirectTo: '/verification',
      successNotification: {
        message:
          'Four digits code sent to your email. Please check your email.',
      },
    };
  } catch (error) {
    return {
      success: true,
      redirectTo: '/verification',
      successNotification: {
        message:
          'Four digits code sent to your email. Please check your email.',
      },
    };
  }
};

export const VerificationAction = async (
  inputs: { code: string },

  navigateToResetPassword: () => void,
  handleDisableButton: (value: boolean) => void
) => {
  try {
    handleDisableButton(true);
    const response = await apiRequestPost({
      url: '/admin/verify-code',
      data: {
        email: await ConfigsStore.get('resetPasswordEmail'),
        code: inputs?.code,
      },
    });
    if (response.status === 201 && response.data) {
      await AccountStore.set('account', response.data);
      navigateToResetPassword();
    }
  } catch (error: any) {
    return {
      success: false,
      error: {
        name: 'Verification Error',
        message: `${error}`,
      },
    };
  } finally {
    handleDisableButton(false);
  }
};

export const ResetPasswordAction = async (
  password: string,
  navigateToLogIn: () => void
) => {
  const inputs = { password };
  await AccountStore.get('account');
  try {
    await apiRequestPost<boolean>({
      url: '/admin/reset-password',
      data: inputs,
      manualTrigger: true,
    });
    await AccountStore.set('account', {});
    await ConfigsStore.set('resetPasswordEmail', '');
    navigateToLogIn();
    message.success('Password Reset Successful');
  } catch (error: any) {
    message.error('Password Reset Failed');
    if (error.response.data.message === 'can_not_use_old_password') {
      message.error('Old Password Cannot be used');
    }
    return {
      success: false,
      error: {
        name: 'Reset Password Error',
        message: `${error.response.data.message === 'can_not_use_old_password' ? 'Old Password Cannot be used' : ''}`,
      },
    };
  }
};
