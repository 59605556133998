import { Refine } from '@refinedev/core';
// import { DevtoolsPanel, DevtoolsProvider } from '@refinedev/devtools';
import { RefineKbar, RefineKbarProvider } from '@refinedev/kbar';
import { useNotificationProvider } from '@refinedev/antd';
import '@refinedev/antd/dist/reset.css';
import routerBindings, {
  DocumentTitleHandler,
  UnsavedChangesNotifier,
} from '@refinedev/react-router-v6';
import { dataProvider } from './rest-data-provider';
import { App as AntdApp } from 'antd';
import { BrowserRouter } from 'react-router-dom';
import { authProvider } from './authProvider';
import { ColorModeContextProvider } from './contexts/color-mode';
import { resources } from './configResources/resources';
import { API_URL } from './configs';
import { axiosInstance } from './Actions/AuthActions';
import { AxiosInstance } from 'axios';
import Stack from './configResources';
import { customTitleHandler } from './Helpers';
import './index.css';

function App() {
  return (
    <BrowserRouter>
      <RefineKbarProvider>
        <ColorModeContextProvider>
          <AntdApp>
            {/* <DevtoolsProvider> */}
            <Refine
              dataProvider={dataProvider(
                API_URL,
                axiosInstance as AxiosInstance
              )}
              notificationProvider={useNotificationProvider}
              authProvider={authProvider}
              routerProvider={routerBindings}
              resources={resources}
              options={{
                syncWithLocation: true,
                warnWhenUnsavedChanges: true,
                useNewQueryKeys: true,
                projectId: 'sSVW82-aqJMzb-z6P8pQ',
              }}
            >
              <Stack />

              <RefineKbar />
              <UnsavedChangesNotifier />
              <DocumentTitleHandler handler={customTitleHandler} />
            </Refine>
            {/* <DevtoolsPanel /> */}
            {/* </DevtoolsProvider> */}
          </AntdApp>
        </ColorModeContextProvider>
      </RefineKbarProvider>
    </BrowserRouter>
  );
}

export default App;
