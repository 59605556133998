import React from 'react';
import { Collapse } from 'antd';
import { Area, DynamicQuestion } from '../../types';
import { DynamicQuestions } from '@components/DynamicQuestions';

export const SubItemsCollapse: React.FC<{
  subItems: Area[];
  onQuestionClick: (question: DynamicQuestion) => void;
}> = ({ subItems, onQuestionClick }) => (
  <Collapse
    items={subItems.map((subItem) => ({
      key: subItem.id,
      label: subItem.name,
      children: (
        <DynamicQuestions
          questions={subItem.dynamic_questions || []}
          onQuestionClick={onQuestionClick}
        />
      ),
    }))}
  />
);
