import React, { useState } from 'react';
import { Button, Card, Col, Form, Input, Layout, Row, theme } from 'antd';
import { useNavigation } from '@refinedev/core';
import { ResetPasswordAction } from '../../Actions/AuthActions';
import { PasswordInput } from '../../components/passwordfield';
import AppIcon from '../../components/app-icon';
import {
  bodyStyles,
  cardTitle,
  containerStyles,
  headStyles,
  layoutStyles,
  resetPasswordTitle,
  resetSubPasswordTitle,
} from './styles';

export const ResetPassword: React.FC = () => {
  const [disabled, setDisabled] = useState(false);
  const { replace } = useNavigation();
  const { token } = theme.useToken();

  const CardTitle = (
    <div style={cardTitle}>
      <AppIcon />
      <div style={{ ...resetPasswordTitle }}>Reset password</div>
      <div style={{ ...resetSubPasswordTitle }}>
        Set a new password for your account so that you can login.
      </div>
    </div>
  );
  const navigateToLogIn = () => {
    replace('/login');
  };

  const handleDisableButton = (value: boolean) => {
    setDisabled(value);
  };

  return (
    <Layout style={layoutStyles}>
      <Row
        justify="center"
        align="middle"
        style={{
          padding: '16px 0',
          minHeight: '100dvh',
        }}
      >
        <Col xs={22}>
          <div
            style={{
              maxWidth: '408px',
              margin: 'auto',
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <Card
              title={CardTitle}
              styles={{ header: headStyles, body: bodyStyles }}
              style={{
                ...containerStyles,
                backgroundColor: token.colorBgElevated,
              }}
            >
              {' '}
              <Form
                layout="vertical"
                onFinish={(values) => {
                  ResetPasswordAction(values.password, navigateToLogIn);
                }}
                requiredMark={false}
              >
                <PasswordInput />

                <Form.Item
                  name="confirmPassword"
                  label={'Confirm password'}
                  dependencies={['password']}
                  rules={[
                    {
                      required: true,
                      message: 'Please confirm your password',
                    },
                    ({ getFieldValue }) => ({
                      validator(_, value) {
                        if (!value || getFieldValue('password') === value) {
                          handleDisableButton(false);
                          return Promise.resolve();
                        }
                        handleDisableButton(true);
                        return Promise.reject(
                          new Error('Passwords do not match')
                        );
                      },
                    }),
                  ]}
                >
                  <Input type="password" size="large" placeholder="••••••••" />
                </Form.Item>
                <Button
                  type="primary"
                  size="large"
                  htmlType="submit"
                  block
                  style={{
                    backgroundColor: token.colorPrimaryTextHover,
                  }}
                  disabled={disabled}
                >
                  <p className="auth-button-text">{'Update'}</p>
                </Button>
              </Form>
            </Card>
          </div>
        </Col>
      </Row>
    </Layout>
  );
};
