import React from 'react';
import { IResourceComponentsProps, useParsed, useShow } from '@refinedev/core';
import { List } from '@refinedev/antd';
import { Table } from 'antd';
import { BHFRes } from '../../types';
// import { useNavigate } from 'react-router-dom';

export const BusinessHealthFormShow: React.FC<
  IResourceComponentsProps
> = () => {
  const { id } = useParsed();
  const {
    queryResult: { data, isLoading },
  } = useShow<BHFRes>({
    resource: `user_submission`,
    id: id,
  });

  return (
    <List title={false}>
      <Table dataSource={data?.data.answer} loading={isLoading}>
        <Table.Column
          dataIndex={['static_questions', 'question_text']}
          title="Question Text"
        />
        <Table.Column dataIndex="answer_num" title="Answer Text" />
      </Table>
    </List>
  );
};
