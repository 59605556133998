// import { HttpError } from '@refinedev/core';
import axios from 'axios';
import { API_KEY, API_URL } from '../../configs';

const axiosInstance = axios.create({
  baseURL: API_URL,
});
axiosInstance.interceptors.request.use(
  async (config) => {
    {
      const token = localStorage.getItem('access_token');
      if (token) {
        {
          config.headers.Authorization = `Bearer ${token}`;
        }
      }

      config.headers['API_KEY'] = API_KEY;

      return config;
    }
  },
  (error) => {
    {
      return Promise.reject(error);
    }
  }
);

export { axiosInstance };
