import { MinusOutlined } from '@ant-design/icons';
import { Descriptions, Typography } from 'antd';
import React from 'react';
import { DynamicQuestion } from '../../types';

export const DynamicQuestions: React.FC<{
  questions: DynamicQuestion[];
  onQuestionClick: (question: DynamicQuestion) => void;
}> = ({ questions, onQuestionClick }) => (
  <Descriptions column={1}>
    {questions.map((question) => (
      <React.Fragment key={question.id}>
        <Descriptions.Item label="Question">
          <Typography.Text
            className="cursor-pointer"
            onClick={() => onQuestionClick(question)}
          >
            {question.question_text ? (
              `${question.question_text} ${question.question_text === 'SWOT analysis' ? question.question_item : ''}`
            ) : question.row_name !== 'null' ? (
              `${question.row_name} (${question.column_name})`
            ) : question.metric ? (
              <div className="flex gap-x-1">
                <div className="font-bold">Metric:</div> {question.metric}
                <div className="font-bold">Description:</div>
                {question.metric_description}
              </div>
            ) : (
              <MinusOutlined />
            )}
          </Typography.Text>
        </Descriptions.Item>
        <Descriptions.Item label="Answer">
          <Typography.Text onClick={() => onQuestionClick(question)}>
            {question.answer[0]?.answer_text ? (
              question.answer[0]?.answer_text
            ) : (
              <MinusOutlined />
            )}
          </Typography.Text>
        </Descriptions.Item>
      </React.Fragment>
    ))}
  </Descriptions>
);
