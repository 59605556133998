import React, { useEffect, useState } from 'react';
import { Layout, Row, Card, Input, Button, Form, theme } from 'antd';
import { VerificationAction } from '../../Actions/AuthActions';
import { useNavigation } from '@refinedev/core';
import AppIcon from '../../components/app-icon';
import {
  headStyles,
  cardTitle,
  resetPasswordTitle,
  resetSubPasswordTitle,
  layoutStyles,
  bodyStyles,
  containerStyles,
} from './styles';
import { ConfigsStore } from '../../Store/index';

export const Verification: React.FC = () => {
  const [disabled, setDisabled] = useState(false);
  const { token } = theme.useToken();
  const { push } = useNavigation();
  const [resetEmail, setResetEmail] = useState<string | undefined>(undefined);
  const navigateToResetPassword = () => {
    push('/reset-password');
  };
  const getResetEmail = async () => {
    return await ConfigsStore.get('resetPasswordEmail');
  };

  useEffect(() => {
    const fetchData = async () => {
      const resetEmail = await getResetEmail();
      // Update state with the reset email once it's available
      setResetEmail(resetEmail);
    };

    fetchData();
  }, []);
  const handleDisableButton = () => {
    setDisabled((preValue) => !preValue);
  };
  const CardTitle = (
    <div style={cardTitle}>
      <AppIcon />
      <div style={{ ...resetPasswordTitle }}>Reset Password</div>
      <div style={{ ...resetSubPasswordTitle }}>
        {resetEmail
          ? `A verification code has been sent to ${resetEmail}. Please check your email.`
          : ''}
      </div>
    </div>
  );
  return (
    <Layout style={layoutStyles}>
      <Row
        justify="center"
        align="middle"
        style={{
          padding: '16px 0',
          minHeight: '100dvh',
        }}
      >
        <Card
          title={CardTitle}
          styles={{ header: headStyles, body: bodyStyles }}
          style={{
            ...containerStyles,
            backgroundColor: token.colorBgElevated,
          }}
        >
          {' '}
          <Form
            layout="vertical"
            onFinish={(values) => {
              VerificationAction(
                { code: values?.code },
                handleDisableButton,
                navigateToResetPassword
              );
            }}
          >
            <Form.Item
              name="code"
              rules={[
                {
                  required: true,
                  message: 'Please enter the verification code',
                },
                {
                  pattern: /^[0-9]{4}$/,
                  message: 'Please enter a 4-digit verification code',
                },
              ]}
            >
              <Input size="large" placeholder="●●●●" />
            </Form.Item>
            <Button
              type="primary"
              size="large"
              htmlType="submit"
              block
              style={{
                backgroundColor: token.colorPrimaryTextHover,
              }}
              disabled={disabled}
            >
              Verify Email
            </Button>
          </Form>
        </Card>
      </Row>
    </Layout>
  );
};
