import React from 'react';
import { IResourceComponentsProps } from '@refinedev/core';
import { Create, useForm } from '@refinedev/antd';
import { Form, Input, Select, Row, Col } from 'antd';
import { inputStyles } from '@pages/styles';

export const AdminCreate: React.FC<IResourceComponentsProps> = () => {
  const { formProps, saveButtonProps } = useForm({
    errorNotification: (error) => {
      return {
        message: `Failed to create admin`,
        description:
          // @ts-ignore
          error.response.data.message == 'Invalid_Email'
            ? 'Email already exists'
            : // @ts-ignore
              error.response.data.message,
        type: 'error',
      };
    },
  });

  return (
    <Create saveButtonProps={saveButtonProps}>
      <Form {...formProps} layout="vertical">
        <Row>
          <Col span={24}>
            <Form.Item
              label="Username"
              name={['username']}
              rules={[
                {
                  required: true,
                  message: 'Please input your username!',
                },
              ]}
            >
              <Input placeholder="Username" autoComplete="off" />
            </Form.Item>
          </Col>
        </Row>
        <Row justify={'space-between'}>
          <Col span={12}>
            <Form.Item
              label="First Name"
              name={['first_name']}
              rules={[
                {
                  required: true,
                  message: 'Please input your first name!',
                },
              ]}
            >
              <Input
                style={inputStyles}
                placeholder="First Name"
                autoComplete="off"
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label="Last Name"
              name={['last_name']}
              rules={[
                {
                  required: true,
                  message: 'Please input your last name!',
                },
              ]}
            >
              <Input
                style={inputStyles}
                placeholder="Last Name"
                autoComplete="off"
              />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={12}>
            <Form.Item
              label="Email"
              name={['email']}
              rules={[
                {
                  required: true,
                  message: 'Please input your email!',
                },
                {
                  type: 'email',
                  message: 'The input is not a valid email!',
                },
              ]}
            >
              <Input
                style={inputStyles}
                placeholder="Email"
                type="email"
                autoComplete="off"
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label="Phone Number" name={['phone_number']}>
              <Input
                style={inputStyles}
                placeholder="Phone Number"
                autoComplete="off"
              />
            </Form.Item>
          </Col>
        </Row>
        <Form.Item
          label="Admin Role"
          name={['admin_role']}
          rules={[
            {
              required: true,
              message: 'Please input the admin role!',
            },
          ]}
        >
          {/* <Input /> */}
          <Select
            options={[
              { label: 'Super Admin', value: 'super_admin' },
              { label: 'Admin', value: 'admin' },
            ]}
            placeholder="Select Admin Role"
          />
        </Form.Item>
        <Form.Item
          name="password"
          label={'Password'}
          rules={[
            { required: true },
            {
              min: 8,
              message: 'Password must be at least 8 characters',
            },
            {
              pattern: /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[\W_]).{8,}$/,
              message: 'Invalid password',
            },
          ]}
        >
          <Input.Password
            type="password"
            autoComplete="off"
            placeholder="●●●●●●●●"
            size="large"
          />
        </Form.Item>
      </Form>
    </Create>
  );
};
