import React from 'react';
import {
  LoginPageProps,
  LoginFormTypes,
  useLink,
  useRouterType,
  useActiveAuthProvider,
  useLogin,
  useRouterContext,
} from '@refinedev/core';
import {
  bodyStyles,
  cardTitle,
  containerStyles,
  headStyles,
  layoutStyles,
  titleStyles,
} from './styles';
import {
  Row,
  Col,
  Layout,
  Card,
  Typography,
  Form,
  Input,
  Button,
  CardProps,
  LayoutProps,
  Divider,
  FormProps,
  theme,
} from 'antd';
import AppIcon from '../../components/app-icon/index';

type LoginProps = LoginPageProps<LayoutProps, CardProps, FormProps>;

/**
 * **refine** has a default login page form which is served on `/login` route when the `authProvider` configuration is provided.
 *
 * @see {@link https://refine.dev/docs/ui-frameworks/antd/components/antd-auth-page/#login} for more details.
 */
export const Login: React.FC<LoginProps> = ({
  providers,
  forgotPasswordLink,
  contentProps,
  wrapperProps,
  formProps,
  hideForm,
}) => {
  const { token } = theme.useToken();
  const [form] = Form.useForm<LoginFormTypes>();
  const routerType = useRouterType();
  const Link = useLink();
  const { Link: LegacyLink } = useRouterContext();

  const ActiveLink = routerType === 'legacy' ? LegacyLink : Link;

  const authProvider = useActiveAuthProvider();
  const { mutate: login, isLoading } = useLogin<LoginFormTypes>({
    v3LegacyAuthProviderCompatible: Boolean(authProvider?.isLegacy),
  });

  const CardTitle = (
    <div style={cardTitle}>
      <AppIcon />
      <Typography.Title
        level={3}
        style={{
          ...titleStyles,
        }}
      >
        Sign In to Your Account
      </Typography.Title>
    </div>
  );

  const renderProviders = () => {
    if (!providers || providers.length === 0) {
      return null;
    }
    return (
      <>
        {providers.map((provider) => {
          return (
            <Button
              key={provider.name}
              type="default"
              block
              icon={provider.icon}
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                width: '100%',
                marginBottom: '8px',
              }}
              onClick={() =>
                login({
                  providerName: provider.name,
                })
              }
            >
              {provider.label}
            </Button>
          );
        })}
        {!hideForm && (
          <Divider>
            <Typography.Text
              style={{
                color: token.colorTextLabel,
              }}
            >
              or
            </Typography.Text>
          </Divider>
        )}
      </>
    );
  };

  const CardContent = (
    <Card
      title={CardTitle}
      style={{
        ...containerStyles,
        backgroundColor: token.colorBgElevated,
      }}
      styles={{ header: headStyles, body: bodyStyles }}
      {...(contentProps ?? {})}
    >
      {renderProviders()}
      {!hideForm && (
        <Form<LoginFormTypes>
          layout="vertical"
          form={form}
          onFinish={(values) => login(values)}
          requiredMark={false}
          initialValues={{
            remember: false,
          }}
          {...formProps}
        >
          <Form.Item
            name="email"
            label={'Email'}
            rules={[
              { required: true },
              {
                type: 'email',
                message: 'Invalid email address',
              },
            ]}
          >
            <Input size="large" placeholder={'Email'} />
          </Form.Item>
          <Form.Item name="password" label={'Password'}>
            <Input.Password
              autoComplete="current-password"
              placeholder="●●●●●●●●"
              size="large"
            />
          </Form.Item>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              marginBottom: '24px',
            }}
          >
            {forgotPasswordLink ?? (
              <ActiveLink
                style={{
                  color: token.colorPrimaryTextHover,
                  fontSize: '12px',
                  marginLeft: 'auto',
                }}
                to="/forgot-password"
              >
                Forgot Password?
              </ActiveLink>
            )}
          </div>
          {!hideForm && (
            <Form.Item>
              <Button
                type="primary"
                size="large"
                htmlType="submit"
                loading={isLoading}
                block
                style={{
                  backgroundColor: token.colorPrimaryTextHover,
                }}
              >
                Sign In
              </Button>
            </Form.Item>
          )}
        </Form>
      )}
    </Card>
  );

  return (
    <Layout style={layoutStyles} {...(wrapperProps ?? {})}>
      <Row
        justify="center"
        align={hideForm ? 'top' : 'middle'}
        style={{
          padding: '16px 0',
          minHeight: '100dvh',
          paddingTop: hideForm ? '15dvh' : '16px',
        }}
      >
        <Col xs={22}>{CardContent}</Col>
      </Row>
    </Layout>
  );
};
