import type { IResourceItem } from '@refinedev/core';
import {
  FileAddOutlined,
  FileDoneOutlined,
  FileSyncOutlined,
  UserOutlined,
} from '@ant-design/icons';
export const resources: IResourceItem[] = [
  // {
  //   name: 'blog_posts',
  //   list: '/blog-posts',
  //   create: '/blog-posts/create',
  //   edit: '/blog-posts/edit/:id',
  //   show: '/blog-posts/show/:id',
  //   meta: {
  //     canDelete: true,
  //   },
  // },
  {
    name: 'onboarding-form',
    list: '/onboarding-form',
    show: '/onboarding-form/show/:id',
    meta: {
      canDelete: false,
      icon: <FileSyncOutlined />,
      label: 'Onboarding Form',
    },
  },
  {
    name: 'business-health-form',
    list: '/business-health-form',
    show: '/business-health-form/show/:id',
    meta: {
      canDelete: false,
      icon: <FileAddOutlined />,
      label: 'Business Health Form',
    },
  },

  {
    name: 'data-driven-form',
    list: '/data-driven-form',
    show: '/data-driven-form/show/:id',
    meta: {
      canDelete: false,
      icon: <FileDoneOutlined />,
      label: 'Data Driven Form',
    },
  },
  {
    name: 'admin',
    list: '/admin',
    create: '/admin/create',
    edit: '/admin/edit/:id',
    show: '/admin/show/:id',
    meta: {
      canDelete: false,
      icon: <UserOutlined />,
      label: 'Admin',
    },
  },
];
