import * as React from 'react';
import { SVGProps } from 'react';
const MenaHubIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={30}
    height={22}
    viewBox="0 0 29 22"
    {...props}
  >
    <defs>
      <clipPath id="a">
        <path d="M25 13h3.996v6H25Zm0 0" />
      </clipPath>
    </defs>
    <path
      d="m3.676 13.938-1.043 3.195-1.035-3.195H.773L0 18.52h.828l.488-2.895.942 2.895h.746l.95-2.895.476 2.895h.82l-.746-4.582ZM9.14 17.07H6.48a.92.92 0 0 0 .31.575c.179.148.405.222.636.214.3.004.586-.117.797-.332l.699.336c-.16.239-.375.434-.625.563-.27.125-.567.187-.863.18-.473.019-.93-.16-1.266-.5a1.75 1.75 0 0 1-.488-1.262c-.012-.48.164-.946.488-1.29a1.61 1.61 0 0 1 1.219-.511c.472-.02.933.168 1.265.512.328.343.489.793.489 1.355Zm-.828-.668a.85.85 0 0 0-.324-.46.909.909 0 0 0-.558-.18.983.983 0 0 0-.61.199c-.133.117-.238.27-.304.441ZM9.809 15.129h.828v.348c.152-.141.324-.254.511-.34a1.19 1.19 0 0 1 .465-.094c.313-.008.617.121.832.352.196.199.29.492.29.882v2.243h-.817v-1.485c.008-.27-.008-.539-.055-.808a.575.575 0 0 0-.183-.301.497.497 0 0 0-.325-.106.605.605 0 0 0-.433.172.937.937 0 0 0-.25.48 4.036 4.036 0 0 0-.035.688v1.36h-.828ZM15.969 15.129h.828v3.39h-.828v-.359c-.14.145-.305.258-.485.344a1.3 1.3 0 0 1-.53.101 1.435 1.435 0 0 1-1.102-.507 1.813 1.813 0 0 1-.47-1.266 1.857 1.857 0 0 1 .454-1.289 1.405 1.405 0 0 1 1.094-.5c.191-.004.383.035.558.113.18.082.344.2.48.348Zm-.871.7a.822.822 0 0 0-.637.276 1.037 1.037 0 0 0-.254.715c-.008.266.082.528.258.723.164.187.394.289.637.285a.84.84 0 0 0 .648-.277c.176-.2.266-.465.254-.73a1.005 1.005 0 0 0-.254-.72.865.865 0 0 0-.652-.273M17.723 13.938h.859v1.726h1.488v-1.726h.86v4.582h-.86v-2.012h-1.488v2.012h-.86ZM21.852 15.129h.84v1.633c-.012.222.007.445.062.664.035.113.11.215.203.285.105.074.227.11.352.105a.584.584 0 0 0 .347-.101.605.605 0 0 0 .215-.297c.047-.207.063-.418.05-.629v-1.66h.829v1.437c0 .594-.043.997-.137 1.215-.097.25-.27.465-.488.614-.25.152-.535.222-.824.21a1.432 1.432 0 0 1-.88-.246 1.328 1.328 0 0 1-.472-.695c-.062-.207-.094-.582-.094-1.121Zm0 0"
      style={{
        stroke: 'none',
        fillRule: 'nonzero',
        fill: '#fff',
        fillOpacity: 1,
      }}
    />
    <g clipPath="url(#a)">
      <path
        d="M26.402 13.945v1.64a1.6 1.6 0 0 1 .485-.339c.18-.074.367-.113.558-.11.418-.007.817.173 1.098.489.305.34.469.79.453 1.254.012.457-.156.902-.469 1.23-.285.32-.683.5-1.105.496a1.3 1.3 0 0 1-.531-.101 1.592 1.592 0 0 1-.489-.332v.351h-.82v-4.578Zm.871 1.953a.859.859 0 0 0-.648.27.953.953 0 0 0-.258.695.993.993 0 0 0 .258.715c.168.18.406.277.648.27a.83.83 0 0 0 .637-.278.985.985 0 0 0 .262-.703.97.97 0 0 0-.258-.695.848.848 0 0 0-.64-.274"
        style={{
          stroke: 'none',
          fillRule: 'nonzero',
          fill: '#fff',
          fillOpacity: 1,
        }}
      />
    </g>
    <path
      d="M9.664 0h6.05l-3.027 9.668ZM16.125 2.355l-3.027 9.75h6.05M9.254 2.355l-3.027 9.75h6.054M16.645 0l3.894 12.105h2.207L18.742 0"
      style={{
        stroke: 'none',
        fillRule: 'nonzero',
        fill: '#f37421',
        fillOpacity: 1,
      }}
    />
  </svg>
);
export default MenaHubIcon;
