import { CSSProperties } from 'react';

export const layoutStyles: CSSProperties = {};

export const containerStyles: CSSProperties = {
  maxWidth: '400px',
  margin: 'auto',
  padding: '32px',
  boxShadow:
    '0px 2px 4px rgba(0, 0, 0, 0.02), 0px 1px 6px -1px rgba(0, 0, 0, 0.02), 0px 1px 2px rgba(0, 0, 0, 0.03)',
};

export const headStyles: CSSProperties = {
  borderBottom: 0,
  padding: 0,
};

export const bodyStyles: CSSProperties = { padding: 0, marginTop: '32px' };

export const titleStyles: CSSProperties = {
  textAlign: 'center',
  marginBottom: 0,
  marginTop: 10,
  fontSize: '24px',
  lineHeight: '32px',
  fontWeight: 700,
  overflowWrap: 'break-word',
  hyphens: 'manual',
  textOverflow: 'unset',
  whiteSpace: 'pre-wrap',
};

export const cardTitle: CSSProperties = {
  margin: 'auto',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'space-around',
};

export const resetPasswordTitle: CSSProperties = {
  textAlign: 'left',
  fontSize: '30px',
  fontStyle: 'normal',
  fontWeight: 600,
  lineHeight: '38px' /* 126.667% */,
  marginTop: '3vh',
};

export const resetSubPasswordTitle: CSSProperties = {
  textAlign: 'left',
  fontSize: '14px',
  fontStyle: 'normal',
  fontWeight: 400,
  lineHeight: '24px' /* 126.667% */,
  marginTop: '1vh',
  textWrap: 'wrap',
};
