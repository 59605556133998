import React from 'react';
import { IResourceComponentsProps } from '@refinedev/core';
import { useTable, List, DateField, BooleanField } from '@refinedev/antd';
import { Table } from 'antd';
import { AdminAccount } from 'src/types';

export const AdminList: React.FC<IResourceComponentsProps> = () => {
  const { tableProps } = useTable({
    syncWithLocation: true,
    sorters: {
      initial: [{ field: 'account_id', order: 'desc' }],
    },
  });

  return (
    <List>
      <Table {...tableProps} rowKey="id" loading={tableProps.loading}>
        <Table.Column dataIndex="id" title="Id" />
        <Table.Column dataIndex={['account', 'username']} title="User Name" />
        <Table.Column dataIndex={['account', 'email']} title="Email" />
        <Table.Column
          dataIndex={['created_at']}
          title="Creation Date"
          render={(value: any) => <DateField value={value} />}
        />
        <Table.Column
          dataIndex={['updated_at']}
          title="Last Updated"
          render={(value: any) => <DateField value={value} />}
        />
        <Table.Column dataIndex="admin_role" title="Admin Role" />

        <Table.Column
          dataIndex={['account_id']}
          title="Account Id"
          sorter={(a: AdminAccount, b: AdminAccount) =>
            a.account_id - b.account_id
          }
        />
        <Table.Column
          dataIndex={['is_deleted']}
          title="Is Deleted"
          render={(value: any) => <BooleanField value={value} />}
        />
      </Table>
    </List>
  );
};
