import React, { useState } from 'react';
import { IResourceComponentsProps } from '@refinedev/core';
import { List, useTable } from '@refinedev/antd';
import {
  Table,
  Form,
  Input,
  Button,
  Upload,
  Select,
  Space,
  message,
} from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import { apiRequest } from '@rest-data-provider/utils/APIs';

import { API_KEY } from '../configs';
import { User } from '../types';
import { AccountStore } from '../Store/AccountStore';

enum image_position {
  cover_right = 'Cover Right',
  cover_center = 'Cover Center',
  cover_left = 'Cover Left',
  page_one = 'Page One',
  page_two = 'Page Two',
  risk_page = 'Risk Page',
  ai_video_link = 'AI Video Link',
}

interface Pillar {
  id: number;
  name: string;
  description: string | null;
}
export interface IAccount {
  signedAccessToken: string;
  signedRefreshToken: string;
  user: User;
}

export const EditBusinessHealthReport: React.FC<
  IResourceComponentsProps
> = () => {
  const {
    tableProps,
    tableQueryResult: { refetch },
  } = useTable<Pillar>({ resource: 'pillar' });

  const [editingPillar, setEditingPillar] = useState<number | null>(null);
  const [form] = Form.useForm();
  const [file, setFile] = useState<File | null>(null);
  const [selectedPosition, setSelectedPosition] = useState<
    keyof typeof image_position | null
  >(null);

  const handleEdit = (record: Pillar) => {
    form.setFieldsValue(record);
    setEditingPillar(record.id);
  };

  const handleSave = async (id: number) => {
    try {
      const values = await form.validateFields();
      await apiRequest({
        method: 'patch',
        url: `pillar/${id}`,
        data: values,
      });
      await refetch();
      setEditingPillar(null);
      form.resetFields();
      message.success('Pillar updated successfully');
    } catch (error) {
      message.error('Failed to update pillar');
    }
  };

  const handleFileChange = (info: any) => {
    if (info.file !== 'uploading') {
      setFile(info.file);
    }
  };

  const handlePositionChange = (value: keyof typeof image_position) => {
    setSelectedPosition(value);
  };

  const handleSubmit = async () => {
    if (!file || !selectedPosition) {
      message.error('Please upload a file and select a position');
      return;
    }

    try {
      const formData = new FormData();
      const account = (await AccountStore.get('account')) as IAccount;
      formData.append('image', file);
      formData.append('image_position', selectedPosition);

      await apiRequest({
        method: 'post',
        url: 'upload/files',
        headers: {
          Authorization: `Bearer ${account?.signedAccessToken}`,
          'Content-Type': 'multipart/form-data',
          API_KEY,
        },
        data: formData,
      });

      message.success('Image and position submitted successfully');
    } catch (error) {
      message.error('Failed to submit image and position');
    }
  };

  return (
    <List title="Edit Business Health Report">
      <Table
        dataSource={tableProps.dataSource}
        loading={tableProps.loading}
        rowKey="id"
      >
        <Table.Column
          dataIndex="name"
          title="Name"
          render={(text, record: Pillar) =>
            editingPillar === record.id ? (
              <Form form={form} component={false}>
                <Form.Item
                  name="name"
                  initialValue={record.name}
                  rules={[{ required: true, message: 'Please enter the name' }]}
                  className="mb-0"
                >
                  <Input
                    onChange={(e) => form.setFieldValue('name', e.target.value)}
                  />
                </Form.Item>
              </Form>
            ) : (
              text
            )
          }
        />
        <Table.Column
          dataIndex="description"
          title="Description"
          render={(text, record: Pillar) =>
            editingPillar === record.id ? (
              <Form form={form} component={false}>
                <Form.Item
                  name="description"
                  initialValue={record.description}
                  rules={[
                    { required: true, message: 'Please enter the description' },
                  ]}
                  className="m-0"
                >
                  <Input
                    onChange={(e) =>
                      form.setFieldValue('description', e.target.value)
                    }
                  />
                </Form.Item>
              </Form>
            ) : (
              text
            )
          }
        />
        <Table.Column
          title="Actions"
          render={(_, record: Pillar) =>
            editingPillar === record.id ? (
              <Space>
                <Button type="primary" onClick={() => handleSave(record.id)}>
                  Save
                </Button>
                <Button onClick={() => setEditingPillar(null)}>Cancel</Button>
              </Space>
            ) : (
              <Button onClick={() => handleEdit(record)}>Edit</Button>
            )
          }
        />
      </Table>
      <Form layout="vertical">
        <Form.Item label="Upload Image">
          <Upload
            listType="picture"
            beforeUpload={(file) => {
              setFile(file); // Update file state when a file is selected
              return false; // Prevent default upload behavior
            }}
            onChange={handleFileChange}
          >
            <Button icon={<UploadOutlined />}>Click to Upload</Button>
          </Upload>
        </Form.Item>
        <Form.Item label="Select Position">
          <Select onChange={handlePositionChange} placeholder="Select Position">
            {Object.keys(image_position).map((positionKey) => (
              <Select.Option key={positionKey} value={positionKey}>
                {image_position[positionKey as keyof typeof image_position]}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Button type="primary" onClick={handleSubmit}>
          Submit
        </Button>
      </Form>
    </List>
  );
};
