import React, { useState } from 'react';
import { IResourceComponentsProps } from '@refinedev/core';
import {
  List,
  DateField,
  BooleanField,
  TextField,
  useTable,
} from '@refinedev/antd';
import { Table, Tag, Select, Form, Button } from 'antd';
import { SyncOutlined } from '@ant-design/icons';
import { downloadOnBoardingExcel } from '../../Actions/ConfigsActions';

export const OnboardingFormList: React.FC<IResourceComponentsProps> = () => {
  const [downloadExcelLoading, setDownloadExcelLoading] =
    useState<boolean>(false);
  const {
    tableProps,
    searchFormProps,
    tableQueryResult: { refetch },
    setFilters,
  } = useTable({
    resource: 'user_submission',
    pagination: {
      current: 1,
      pageSize: 10,
    },
    filters: {
      initial: [
        {
          field: 'form_type',
          operator: 'eq',
          value: 'ON_BOARDING',
        },
      ],
    },
    //@ts-ignore
    onSearch: (values: { form_status: string }) => {
      let filters = [
        {
          field: 'form_status',
          operator: 'eq',
          value: values.form_status,
        },
      ];
      return filters;
    },
  });

  const handleReset = () => {
    searchFormProps.form?.resetFields();
    //@ts-ignore
    setFilters((currentFilters) => {
      return currentFilters.filter(
        (filter: { field: string }) => filter.field === 'form_type'
      );
    }, 'replace');
    refetch();
  };

  const updateLoading = (value: boolean) => {
    setDownloadExcelLoading(value);
  };
  const onExport = async () => {
    await downloadOnBoardingExcel(updateLoading);
  };
  return (
    <List>
      <div className="flex justify-between items-center mb-2">
        <Form
          layout="inline"
          {...searchFormProps}
          onValuesChange={() => {
            searchFormProps.form?.submit();
          }}
          className="mb-2"
        >
          <Form.Item name={'form_status'}>
            <Select placeholder="Form Status">
              <Select.Option value="in_progress">In Progress</Select.Option>
              <Select.Option value="submitted">Completed</Select.Option>
            </Select>
          </Form.Item>

          <Button onClick={() => handleReset()} type="primary">
            <SyncOutlined />
            <div className="text-white">Reset Filters</div>
          </Button>
        </Form>
        <Button
          loading={downloadExcelLoading}
          onClick={onExport}
          type="primary"
        >
          Export
        </Button>
      </div>
      <Table
        dataSource={tableProps.dataSource}
        loading={tableProps.loading}
        rowKey={'id'}
      >
        <Table.Column dataIndex="id" title="Id" />
        <Table.Column
          dataIndex={['user', 'company_name']}
          title="Company Name"
          render={(value: string) => <TextField value={value || '-'} />}
        />
        <Table.Column
          dataIndex={['user', 'city']}
          title="City"
          render={(value: string) => <TextField value={value || '-'} />}
        />
        <Table.Column
          dataIndex={['user', 'business_type']}
          title="Business type"
        />
        <Table.Column
          dataIndex="form_status"
          title="Form Status"
          render={(value: string) => (
            <Tag color={value === 'in_progress' ? 'blue' : 'green'}>
              {value === 'in_progress' ? 'IN PROGRESS' : 'COMPLETED'}
            </Tag>
          )}
        />
        <Table.Column
          dataIndex={['created_at']}
          title="Creation Date"
          render={(value: Date) => <DateField value={value} />}
        />
        <Table.Column
          dataIndex={['updated_at']}
          title="Last Updated"
          render={(value: Date) => <DateField value={value} />}
        />
        <Table.Column
          dataIndex={['is_deleted']}
          title="Is Deleted"
          render={(value: boolean) => <BooleanField value={value} />}
        />
      </Table>
    </List>
  );
};
