import React from 'react';
import { Login } from '../pages/Auth/Login';
import { ThemedLayoutV2, Header, ThemedSiderV2 } from '@refinedev/antd';
import { Authenticated, ErrorComponent } from '@refinedev/core';
import {
  CatchAllNavigate,
  NavigateToResource,
} from '@refinedev/react-router-v6';
import { Routes, Route, Outlet } from 'react-router-dom';
import { Verification } from '@pages/Auth/Verification';
import { ForgotPasswordPage } from '@pages/Auth/forgotPassword';
import { ResetPassword } from '@pages/Auth/resetPassword';
import {
  OnboardingFormList,
  OnboardingFormShow,
} from '../pages/onboarding-form';
import {
  BusinessHealthFormList,
  BusinessHealthFormShow,
} from '../pages/business-health-form';
import {
  DataDrivenFormList,
  DataDrivenFormShow,
} from '../pages/data-driven-form';
import { AdminCreate, AdminList } from '@pages/Admin';
import { ThemedTitleV2 } from '../components/layout/title';
import { EditBusinessHealthReport } from '@pages/edit-business-health-report';

const Stack = () => {
  return (
    <Routes>
      <Route
        element={
          <Authenticated
            key="authenticated-inner"
            fallback={<CatchAllNavigate to="/login" />}
            redirectOnFail="/login"
          >
            <ThemedLayoutV2
              Header={() => <Header />}
              Sider={(props) => <ThemedSiderV2 {...props} fixed />}
              Title={({ collapsed }) => (
                <ThemedTitleV2 collapsed={collapsed} text="MenaHub Dashboard" />
              )}
            >
              <Outlet />
            </ThemedLayoutV2>
          </Authenticated>
        }
      >
        <Route
          index
          element={<NavigateToResource resource="onboarding-form" />}
        />
        <Route path="/onboarding-form">
          <Route index element={<OnboardingFormList />} />
          <Route path="show/:id" element={<OnboardingFormShow />} />
        </Route>
        <Route path="/business-health-form">
          <Route index element={<BusinessHealthFormList />} />
          <Route path="show/:id" element={<BusinessHealthFormShow />} />
        </Route>
        <Route path="/edit-business-health-report">
          <Route index element={<EditBusinessHealthReport />} />
        </Route>
        <Route path="/data-driven-form">
          <Route index element={<DataDrivenFormList />} />
          <Route path="show/:id" element={<DataDrivenFormShow />} />
        </Route>
        <Route path="/admin">
          <Route index element={<AdminList />} />
          <Route path="create" element={<AdminCreate />} />
        </Route>
        <Route path="*" element={<ErrorComponent />} />
      </Route>
      <Route
        element={
          <Authenticated key="authenticated-outer" fallback={<Outlet />}>
            <NavigateToResource />
          </Authenticated>
        }
      >
        <Route path="/login" element={<Login />} />
        <Route path="/forgot-password" element={<ForgotPasswordPage />} />
        <Route path="/verification" element={<Verification />} />
        <Route path="/reset-password" element={<ResetPassword />} />
      </Route>
    </Routes>
  );
};

export default Stack;
