import React, { useEffect, useRef } from 'react';
import { Form, Input, Button, Tooltip } from 'antd';
import {
  SendOutlined,
  CloseOutlined,
  CheckOutlined,
  ExpandAltOutlined,
} from '@ant-design/icons';
import './styles.css';
import { AiMessage } from 'src/types';

interface ChatboxProps {
  chatbotMessages: AiMessage[];
  inputValue: string;
  setInputValue: React.Dispatch<React.SetStateAction<string>>;
  handleSubmit: (e: React.FormEvent) => void;
  aiLoading: boolean;
  handleConfirmAnswer: (answerText: string, answer_id: number) => void;
  confirmAiLoading: boolean;
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const Chatbox: React.FC<ChatboxProps> = ({
  chatbotMessages,
  inputValue,
  setInputValue,
  handleSubmit,
  aiLoading,
  handleConfirmAnswer,
  confirmAiLoading,
  isOpen,
  setIsOpen,
}) => {
  const messagesEndRef = useRef<HTMLDivElement>(null);

  const handleToggleChatbox = () => {
    setIsOpen(!isOpen);
  };

  const handleOpenChatbox = () => {
    setIsOpen(true);
  };

  useEffect(() => {
    scrollToBottom();
  }, [chatbotMessages]);

  const scrollToBottom = () => {
    if (messagesEndRef.current) {
      messagesEndRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  };

  if (!isOpen) {
    return (
      <div className="closed-chatbox-container">
        <Button
          type="text"
          onClick={handleOpenChatbox}
          className={`w-full flex items-start  border-primary open-chatbox-button `}
          icon={<ExpandAltOutlined />}
        >
          Open Chatbox
        </Button>
      </div>
    );
  }

  return (
    <div className="min-w-44 chatbox-container bg-gray-100 p-4 rounded-lg">
      <div className="chatbox-header flex justify-between items-center mb-2">
        <h3 className="text-lg font-medium">Chatbox</h3>
        <Button
          type="text"
          icon={<CloseOutlined />}
          onClick={handleToggleChatbox}
          className="text-gray-500 hover:text-gray-700"
        />
      </div>
      <div className="flex p-3 flex-col overflow-y-auto max-h-80 bg-white rounded-lg">
        {chatbotMessages.map((msg, index) => (
          <div
            key={index}
            className={`message-content p-3 rounded-lg mb-2 max-w-xs ${msg.sender === 'Bot' ? 'bg-primary text-white self-start' : 'bg-gray-300 text-black self-end'}`}
          >
            <p>{`${msg.sender}: ${msg.message}`}</p>
            {msg.sender === 'Bot' && (
              <Tooltip title="Confirm Answer" className="bg-white">
                <Button
                  size="small"
                  icon={<CheckOutlined />}
                  className="text-primary"
                  onClick={() =>
                    handleConfirmAnswer(msg.message, msg.answer_id)
                  }
                  loading={confirmAiLoading}
                />
              </Tooltip>
            )}
          </div>
        ))}
        <div ref={messagesEndRef} />
      </div>
      <Form
        layout="inline"
        onFinish={handleSubmit}
        className="flex content-center items-center mt-2 w-full"
      >
        <Form.Item className="flex-grow w-[85%]">
          <Input
            type="text"
            placeholder="Type a message..."
            value={inputValue}
            onChange={(e) => setInputValue(e.target.value)}
            className="message-input"
            size="large"
          />
        </Form.Item>
        <Button
          type="primary"
          htmlType="submit"
          icon={<SendOutlined />}
          className="send-button"
          loading={aiLoading}
          size="middle"
          disabled={!inputValue.trim()}
        />
      </Form>
    </div>
  );
};

export default Chatbox;
