import { Button, Modal, Upload, message } from 'antd';
import React, { useState } from 'react';
import { CloseIcon } from '../../Assets/Svgs';
import { UploadOutlined } from '@ant-design/icons';
import { axiosInstance } from '../../rest-data-provider/utils';
import { UploadFile } from 'antd/lib/upload/interface';

interface Props {
  open: boolean;
  updateModalOpen: (value: boolean) => void;
  submmittedFormId: number;
}

const ImportReportModal: React.FC<Props> = ({
  open,
  updateModalOpen,
  submmittedFormId,
}: Props) => {
  const handleCancel = () => {
    updateModalOpen(false);
    setFileList([]);
    setLoading(false);
  };

  const [fileList, setFileList] = useState<UploadFile[]>([]);
  const [loading, setLoading] = useState(false);

  const handleFileChange = (info: { fileList: UploadFile[] }) => {
    setFileList(info.fileList);
  };

  const handleUpload = async () => {
    setLoading(true);
    if (fileList.length > 0) {
      const formData = new FormData();
      formData.append('file', fileList[0].originFileObj as any); // Cast to any since UploadFile does not extend File

      try {
        await axiosInstance.post(
          `/upload/uploadDocumentFile/${submmittedFormId}`,
          formData
        );
        message.success('File uploaded successfully', 3);
        updateModalOpen(false);
        setFileList([]);
      } catch (error) {
        message.error('Something went wrong', 3);
      } finally {
        setLoading(false);
      }
    } else {
      setLoading(false);
      message.warning('Please select a file to upload', 3);
    }
  };
  const handleBeforeUpload = (file: UploadFile) => {
    setFileList([file]);
    return false;
  };
  return (
    <Modal
      open={open}
      closeIcon={<CloseIcon />}
      centered
      width={498}
      style={{ borderRadius: '24px' }} // Corrected the style prop
      title={
        <div className="text-neutral90 text-base font-semibold">
          Upload Report
        </div>
      }
      onCancel={handleCancel}
      footer={null}
    >
      <div className="flex flex-col items-center gap-y-6 mt-6">
        <div className="text-gray500">
          Please browse the file you want to upload
        </div>
        <div className="w-full">
          <div className="text-gray400">Upload file</div>
          <Upload
            className="flex w-full"
            onChange={handleFileChange}
            accept=".doc, .docx"
            maxCount={1}
            fileList={fileList}
            beforeUpload={handleBeforeUpload}
          >
            <Button className="upload-button w-full text-gray400">
              Browse Files
              <UploadOutlined className="upload-icon" />
            </Button>
          </Upload>
        </div>
        <Button
          size="large"
          type="primary"
          className="w-full rounded-[12px] text-white bg-elmokhtabarPrimary h-14"
          htmlType="submit"
          onClick={handleUpload}
          loading={loading}
          disabled={fileList.length === 0}
        >
          Upload
        </Button>
        <Button
          className="text-[#848484] hover:cursor-pointer bg-inherit border-none shadow-none"
          onClick={handleCancel}
        >
          Cancel
        </Button>
      </div>
    </Modal>
  );
};

export default ImportReportModal;
