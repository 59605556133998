import { Button, Descriptions, Form, Input, Modal, message } from 'antd';
import React, { useEffect, useState } from 'react';
import { CloseIcon } from '../../Assets/Svgs';
import { ArrowRightOutlined } from '@ant-design/icons';
import { apiRequest } from '../../rest-data-provider/utils/APIs';
import { Answer, BHFAIQuesRes, BHFAIRes } from '../../types';

interface Props {
  open: boolean;
  updateModalOpen: (value: boolean) => void;
  id: number;
  refetch: () => void;
  formId: number;
}

const BHAIModal: React.FC<Props> = (props: Props) => {
  const [loading, setLoading] = useState(false);
  const [nextLoading, setNextLoading] = useState(false);
  const [resLoading, setResLoading] = useState(false);
  const [answer, setAnswer] = useState<Answer[]>([]);
  const [firstGenerated, setFirstGenerated] = useState(true);
  const [questionOrder, setQuestionOrder] = useState(0);
  const [response, setResponse] = useState<BHFAIRes | undefined>();
  const [step, setStep] = useState(1);
  const [form] = Form.useForm();

  useEffect(() => {
    const fetchAnswer = async () => {
      setLoading(true);
      try {
        const { data } = await apiRequest<BHFAIQuesRes>({
          url: `user_submission/onBoardingFormQuestionsAI/${props.id}`,
        });
        setAnswer(data.answer);
        setLoading(false);
      } catch (error) {
        setLoading(false);
        message.error('Something went wrong');
      }
    };

    fetchAnswer();
  }, [props.id]);

  const handleGenerate = async (answerId: number) => {
    setFirstGenerated(false);
    setResLoading(true);
    try {
      const { data } = await apiRequest<BHFAIRes>({
        url: `user_submission/onBoardingFormAnswerFedToAI/${answerId}`,
      });
      setResponse(data);
      setResLoading(false);
    } catch (error) {
      message.error('Something went wrong');
      setResLoading(false);
    }
  };

  const handleNext = async () => {
    setNextLoading(true);
    try {
      await apiRequest({
        url: `/user_submission/confirmAiAnswer`,
        data: {
          answer_id: answer[questionOrder].id,
          answer_text: response?.message.content,
        },
        method: 'POST',
      });
      message.success('Answer submitted successfully');
      if (questionOrder < answer.length - 1) {
        setQuestionOrder(questionOrder + 1);
      } else {
        setQuestionOrder(0);
        setStep(2);
      }
      setResponse(undefined);
    } catch (error) {
      message.error('Something went wrong');
    } finally {
      setNextLoading(false);
    }
    setFirstGenerated(true);
  };
  const handleSubmit = async (value?: { ai_video_link: string }) => {
    try {
      await apiRequest({
        url: `/user_submission/addAiVideoLink`,
        data: {
          ai_video_link: value?.ai_video_link,
          user_submission_id: props.formId,
        },
        method: 'POST',
      });
      setStep(1);
      setFirstGenerated(true);
      setQuestionOrder(0);
      setResponse(undefined);
      form.resetFields();
      props.refetch();
      props.updateModalOpen(false);
      message.success('Link added successfully');
    } catch (error) {
      message.error('Something went wrong');
    }
  };

  const handleCancel = () => {
    setStep(1);
    setFirstGenerated(true);
    setQuestionOrder(0);
    setResponse(undefined);
    form.resetFields();
    props.updateModalOpen(false);
  };
  return (
    <Modal
      open={props.open}
      closeIcon={
        <button onClick={() => handleCancel()}>
          <CloseIcon />
        </button>
      }
      centered
      width={498}
      styles={{ content: { borderRadius: '24px' } }}
      title={
        <div className="text-neutral90 text-base font-semibold">
          AI Content Generation
        </div>
      }
      footer={false}
      loading={loading}
    >
      {step === 1 ? (
        <div>
          <div className="flex flex-col">
            {answer.length > 0 && (
              <Descriptions
                title={<div>Question {questionOrder + 1}</div>}
                column={1}
              >
                <Descriptions.Item label={<div>Question</div>}>
                  {answer[questionOrder].answer_type === 'essay'
                    ? answer[questionOrder].static_questions.question_text
                    : answer[questionOrder].static_questions.question_json_text
                        .name}
                </Descriptions.Item>
                <Descriptions.Item label="Answer">
                  {answer[questionOrder].answer_type === 'essay'
                    ? answer[questionOrder].answer_text
                    : answer[questionOrder].answer_msl.join(', ')}
                </Descriptions.Item>
                {response && (
                  <Descriptions.Item
                    // style={{ maxHeight: '200px' }}
                    label="Result"
                  >
                    <div className="overflow-y-auto max-h-40 ">
                      {response.message.content}
                    </div>
                  </Descriptions.Item>
                )}
              </Descriptions>
            )}
          </div>
          <div className="flex justify-around gap-y-6 mt-6">
            <Button
              size="large"
              type="primary"
              className="w-[48%] text-primary border-primary rounded-[12px] h-14 bg-white"
              onClick={() => handleGenerate(answer[questionOrder].id)}
              loading={resLoading}
            >
              {firstGenerated ? 'Generate' : 'Generate Again'}
            </Button>
            <Button
              size="large"
              type="primary"
              className="w-[48%] rounded-[12px] h-14"
              onClick={handleNext}
              loading={nextLoading}
            >
              <div className="flex justify-center gap-x-2">
                <div>Next</div>
                <ArrowRightOutlined />
              </div>
            </Button>
          </div>
        </div>
      ) : (
        <div className="flex flex-col">
          <Form form={form} layout="vertical" onFinish={handleSubmit}>
            <Form.Item
              label="AI Video Link"
              name={'ai_video_link'}
              rules={[
                {
                  required: true,
                  message: 'Please enter your AI video link',
                },
                {
                  type: 'url',
                  message: 'Please enter a valid URL',
                },
              ]}
            >
              <Input placeholder="Enter your ai video link" />
            </Form.Item>
            <Button
              size="large"
              type="primary"
              className="w-full"
              htmlType="submit"
            >
              Generate Report
            </Button>
          </Form>
        </div>
      )}
    </Modal>
  );
};

export default BHAIModal;
