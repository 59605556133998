import * as React from 'react';
const AppIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={270.952}
    height={110.327}
    data-name="Group 173372"
    viewBox="0 0 270.952 100.327"
  >
    <defs>
      <clipPath id="a">
        <path
          fill="none"
          d="M0 0h270.952v200.327H0z"
          data-name="Rectangle 161173"
        />
      </clipPath>
    </defs>
    <g data-name="Group 173371">
      <g fill="#f37421" clipPath="url(#a)" data-name="Group 173370">
        <path d="M90.295 0h56.554l-28.277 87.888Z" data-name="Path 38276" />
        <path d="m150.671 21.399-28.277 88.652h56.554" data-name="Path 38277" />
        <path d="m86.474 21.399-28.277 88.652h56.554" data-name="Path 38278" />
        <path
          d="m155.527 0 36.413 110.051h20.635L175.127 0"
          data-name="Path 38279"
        />
      </g>
    </g>
  </svg>
);
export default AppIcon;
