import React from 'react';
import {
  ForgotPasswordPageProps,
  ForgotPasswordFormTypes,
  useRouterType,
  useLink,
  useRouterContext,
  useForgotPassword,
} from '@refinedev/core';
import {
  layoutStyles,
  containerStyles,
  headStyles,
  bodyStyles,
  cardTitle,
  resetPasswordTitle,
  resetSubPasswordTitle,
} from './styles';
import {
  Row,
  Col,
  Layout,
  Card,
  Typography,
  Form,
  Input,
  Button,
  LayoutProps,
  CardProps,
  FormProps,
  theme,
} from 'antd';
import AppIcon from '../../components/app-icon';

type ResetPasswordProps = ForgotPasswordPageProps<
  LayoutProps,
  CardProps,
  FormProps
>;

/**
 * **refine** has forgot password page form which is served on `/forgot-password` route when the `authProvider` configuration is provided.
 *
 * @see {@link https://refine.dev/docs/ui-frameworks/antd/components/antd-auth-page/#forgot-password} for more details.
 */
export const ForgotPasswordPage: React.FC<ResetPasswordProps> = ({
  loginLink,
  wrapperProps,
  contentProps,
  formProps,
}) => {
  const { token } = theme.useToken();
  const [form] = Form.useForm<ForgotPasswordFormTypes>();
  const routerType = useRouterType();
  const Link = useLink();
  const { Link: LegacyLink } = useRouterContext();

  const ActiveLink = routerType === 'legacy' ? LegacyLink : Link;

  const { mutate: forgotPassword, isLoading } =
    useForgotPassword<ForgotPasswordFormTypes>();

  const CardTitle = (
    <div style={cardTitle}>
      <AppIcon />
      <div style={{ ...resetPasswordTitle }}>Reset Password</div>
      <div style={{ ...resetSubPasswordTitle }}>
        Enter your email address, and we will send you a verification code.
      </div>
    </div>
  );
  const CardContent = (
    <Card
      title={CardTitle}
      styles={{ header: headStyles, body: bodyStyles }}
      style={{
        ...containerStyles,
        backgroundColor: token.colorBgElevated,
      }}
      {...(contentProps ?? {})}
    >
      <Form<ForgotPasswordFormTypes>
        layout="vertical"
        form={form}
        onFinish={(values) => forgotPassword(values)}
        requiredMark={false}
        {...formProps}
      >
        <Form.Item
          name="email"
          label={'Email'}
          rules={[
            { required: true },
            {
              type: 'email',
              message: 'Invalid email address',
            },
          ]}
        >
          <Input type="email" size="large" placeholder="Email" />
        </Form.Item>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          {loginLink ?? (
            <Typography.Text
              style={{
                fontSize: 12,
                marginLeft: 'auto',
              }}
            >
              Have an account?{' '}
              <ActiveLink
                style={{
                  fontWeight: 'bold',
                  color: token.colorPrimaryTextHover,
                }}
                to="/login"
              >
                Sign in
              </ActiveLink>
            </Typography.Text>
          )}
        </div>
        <Form.Item
          style={{
            marginTop: '24px',
            marginBottom: 0,
          }}
        >
          <Button
            type="primary"
            size="large"
            htmlType="submit"
            loading={isLoading}
            block
            style={{
              backgroundColor: token.colorPrimaryTextHover,
            }}
          >
            Submit
          </Button>
        </Form.Item>
      </Form>
    </Card>
  );

  return (
    <Layout style={layoutStyles} {...(wrapperProps ?? {})}>
      <Row
        justify="center"
        align="middle"
        style={{
          padding: '16px 0',
          minHeight: '100dvh',
        }}
      >
        <Col xs={22}>{CardContent}</Col>
      </Row>
    </Layout>
  );
};
