import { Params } from '../types';

export const customTitleHandler = ({ resource, action }: Params) => {
  let title = 'MENAHUB Dashboard';
  if (resource && action) {
    title = `${resource.meta?.label} `;
  }

  return title;
};
